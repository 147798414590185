<template>
    <LiefengContent>
        <template v-slot:title>
            {{ $route.query.name || "发群公告" }}
        </template>
        <template v-slot:toolsbarRight></template>
        <template v-slot:contentArea>
            <div class="iframe-div">
                <div class="left-div">
                    <iframe class="left-frame" id="iframe" v-if="chatUrl && chatUrl != ''" :src="chatUrl"></iframe>
                    <Button class="btn" type="primary" @click="changeBack">返回</Button>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import proxy from "@/api/proxy.js"
export default {
    components: {
        LiefengContent,
    },
    data() {
        return {
            chatUrl: "",
            setUrl: "",
        }
    },
    created() {
        this.chatUrl = `${proxy["/custom"].target}/#/pages/im/group/groupsend?sendType=${this.$route.query.sendType}&token=${window.sessionStorage.getItem(
            "accessToken"
        )}&isShow=false`
    },
    methods: {
        changeBack() {
            this.chatUrl = ""
            this.$nextTick(() => {
                this.chatUrl = `${proxy["/custom"].target}/#/pages/im/group/groupsend?sendType=${this.$route.query.sendType}&token=${window.sessionStorage.getItem(
                    "accessToken"
                )}&isShow=false`
            })
        },
    },
}
</script>

<style scoped lang="less">
.iframe-div {
    display: flex;
    justify-content: center;
    .left-div {
        width: 450px;
        height: 800px;
        margin: 0 auto;
        display: flex;
        position: relative;
        .btn {
            position: absolute;
            right: -70px;
            top: 0;
        }
    }
    .right-div {
        width: 450px;
        height: 800px;
        margin-left: auto;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}
</style>
